module controllers {
    export module consigment {
        interface IConsignmentPurchaseOrderCreateScope extends ng.IScope {
        }
        
        interface IConsignmentCreatePurchaseOrderParams extends ng.ui.IStateParamsService {
            conId: number;
            supId: number;
            conType: number;
        }

        export class consignmentCreatePurchaseOrderCtrl
        {
            static $inject = [
                "$scope",
                "$rootScope",
                "$stateParams",
                "consignmentService",
                "$timeout",
                "generalService",
                "$state",
                "entityService",
                "shipperService",
                "consigneeService",
                "counterService",
                "consignmentTypeService",
                "$q",
                "bsLoadingOverlayService",
                "routeCodeService",
                "supplierService",
                "customerService",
                "isInbound",
                "commercialInvoiceService",
                "commercialInvoiceDeliveryInstructionAllocationService",
                '$anchorScroll',
                "$uibModal",
                "commercialInvoiceSkuService"
            ];

            selectedTab: number = 0;
            ShowConsignment: boolean = true;

            ShowPurchaseOrdersGrid: boolean = false;
            ShowInvoiceLinesGrid: boolean = false;
            NextStepButton: string = "Next";
          
            disableConsignment: boolean = false;
            disablePurchaseOrders: boolean = true;
            disableInvoiceLines: boolean = true;
            showAllPO: boolean = true;
            allowPriceOverride: boolean = false;

            consignmentId: number;
            commercialInvoiceId: number;

            varianceAuthUser: interfaces.applicationcore.IDropdownModel;
            varianceAuthUserId: number = 0; //by default not authorized for variance
           

            //Entity Variables
            public ownerEntityId: number;
            entityList: Array<interfaces.applicationcore.IEntity>;

            showGrid: boolean = false;
            IsLoading: boolean = false;

            //Shipper Variables
            shipperModel: interfaces.applicationcore.IDropdownModel;
            shipperList: Array<interfaces.applicationcore.IDropdownModel>;

            //Consignee Variables
            consigneeModel: interfaces.applicationcore.IDropdownModel;
            consigneeList: Array<interfaces.applicationcore.IDropdownModel>;

            customNumber: string;

            //Counter Variables
            consignmentCounterId: number;
            counterTypeDefer: ng.IDeferred<any>;
            consignmentCounterList: Array<interfaces.applicationcore.IDropdownModel>;

            //Consignment Type Variables
            consignmentTypeList: Array<interfaces.applicationcore.IDropdownModel>;
            consignmentTypeDefer: ng.IDeferred<any>;

            //Route Code Variables
            loadingCountryID: number;

            routeModel: interfaces.applicationcore.IDropdownModel;
            routeCodeList: Array<interfaces.applicationcore.IDropdownModel>;

            //Consignment Variables
            consignmentSelected: interfaces.applicationcore.IDropdownModel;
            consignmentList: Array<interfaces.applicationcore.IDropdownModel>;

            //Supplier Variables
            supplierModel: interfaces.applicationcore.IDropdownModel;
            supplierList: Array<interfaces.applicationcore.IDropdownModel>;

            //Customer Variables
            customerModel: interfaces.applicationcore.IDropdownModel;
            customerList: Array<interfaces.applicationcore.IDropdownModel>;

            //Variables to call getSalesOrderListForCI() in the Next controller.
            
            apiPOList: uiGrid.IGridApi;
            selectedPO: uiGrid.IGridRow;
            selectedPOs: interfaces.logistics.ICIDIDisplay[] = [];
            notSelectedPOs: interfaces.logistics.ICIDIDisplay[] = [];

            apiILList: uiGrid.IGridApi;
            selectedIL: uiGrid.IGridRow;
            
            loadPromises: ng.IPromise<any>[];

            //Get Sales Order Allocation Lines || Allocation Line Submission
            orderIdList: interfaces.logistics.ICIDISubmit[] = [];

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            EnableNextButton: boolean = true;
            Show: boolean = true;

            conId: number;
            supId: number;
            conType: number;

            POList: interfaces.logistics.ICIDIDisplay[] = [];
            ILList: interfaces.logistics.ICIDILineDisplay[] = [];

            allowCustomConsignmentCounter : boolean = false;

            customNumberCheck: boolean = false;

            constructor(private $scope: IConsignmentPurchaseOrderCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $stateParams: IConsignmentCreatePurchaseOrderParams,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private shipperService: interfaces.master.IShipperService,
                private consigneeService: interfaces.master.IConsigneeService,
                private counterService: interfaces.master.ICounterService,
                private consignmentTypeService: interfaces.logistics.IConsignmentTypeService,
                private $q: ng.IQService,
                private bsLoadingOverlayService,
                private routeCodeService: interfaces.master.IRouteCodeService,
                private supplierService: interfaces.master.ISupplierService,
                private customerService: interfaces.master.ICustomerService,
                private isInbound: boolean,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                private commercialInvoiceDeliveryInstructionAllocationService: interfaces.logistics.ICommercialInvoiceDeliveryInstructionAllocationService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                public commercialInvoiceSkuService: interfaces.logistics.ICommercialInvoiceSkuService,
            ) {
                //Get Entities Data
                this.loadPromises = [];

                if (this.$stateParams.conId !== null && this.$stateParams.supId !== null && this.$stateParams.conType !== null) {
                    this.SetupForExistingCI();
                    this.EnableNextButton = true;
                } 
                
                this.loadPromises.push(this.loadEntities());
                this.loadPromises.push(this.getCurrentEntity());
                this.loadPromises.push(this.getCurrentCountryID());

                this.consignmentTypeDefer = $q.defer();
                this.loadPromises.push(this.consignmentTypeDefer.promise);

                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises);
                    });

                $timeout(() => {
                    this.showGrid = true;
                    this.IsLoading = true;
                });
            }

            SetupForExistingCI() {
                this.conId = this.$stateParams.conId;
                this.supId = this.$stateParams.supId;
                this.conType = this.$stateParams.conType;
                this.selectedTab = 1;
                this.disableConsignment = true;
                this.showAllPO = false;
            }

           
            //Loading Consignment Types
            loadConsignmentTypes() {

                if (this.ownerEntityId) {

                    this.consignmentTypeService.getDropdownList(this.ownerEntityId, this.isInbound).query({}, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        if (this.consignmentTypeDefer) {
                            this.consignmentTypeDefer.resolve();
                        }

                        this.consignmentTypeList = result;

                        angular.forEach(this.consignmentTypeList, (o) => {
                            if (o.Selected && !this.conType) {
                                this.conType = o.Id;
                                this.consignmentTypeChanged();
                            }
                        });

                    }, (failureData) => {
                        if (this.consignmentTypeDefer) {
                            this.consignmentTypeDefer.reject();
                        }
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.consignmentTypeList = <ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>[];
                    });
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Loading Consignment Counters
            loadConsignmentCounter() {
                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: Enum.EnumCounterTypes.ImportConignment,
                        isInbound: this.isInbound
                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.consignmentCounterList = result;

                        if (this.counterTypeDefer) {
                            this.counterTypeDefer.resolve();
                        }

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.consignmentCounterId = o.Id;
                            }
                        });

                    }, (failureData) => {

                        if (this.counterTypeDefer) {
                            this.counterTypeDefer.reject();
                        }

                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Loading Entities
            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    this.loadRouteDefaults();
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCurrentEntity() {
                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;

                    this.loadConsignmentTypes();
                    this.loadConsignmentCounter();

                    if (this.$stateParams.conId !== null && this.$stateParams.supId !== null && this.$stateParams.conType !== null && this.ownerEntityId != undefined) {
                        this.OpenExistingCI();
                    }

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCurrentCountryID() {
                return this.entityService.getEntityCountry().get({
                    entityId: this.ownerEntityId
                }, (result: interfaces.applicationcore.ICountry) => {
                    this.loadingCountryID = result.Id;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading the Shippers
            loadShippers(searchText: string) {

                if (this.ownerEntityId) {
                    return this.shipperService.getShipperDropdown(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            shipperChanged(model: interfaces.applicationcore.IDropdownModel) {
                //Always try and get Route default from Shipper
                    this.loadShipperDefaults(model.Id);                
            }

            loadShipperDefaults(shipperId: number){
                return this.shipperService.getShipperDefaults(shipperId).get({

                }, (data: interfaces.master.IShipperDefaults) => {
                    if (data.Route) {
                        this.routeModel = data.Route;
                    }                    
                    if (data.Supplier){
                        this.supplierModel = data.Supplier;
                    }
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading the Consignees
            loadConsignees(searchText: string) {

                if (this.ownerEntityId) {
                    return this.consigneeService.getDropdownList(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            customCounterChanged() {
                this.consignmentCounterId = null;
            }

            //Reload everything for the new Entity
            ownerEntityChanged() {
                this.consigneeList = [];
                this.consigneeModel = undefined;

                this.supplierList = [];
                this.supplierModel = undefined;

                this.customerList = [];
                this.customerModel = undefined;

                this.shipperModel = undefined;
                this.conType = undefined;
                this.consignmentCounterId = undefined;

                this.shipperList = undefined;
                this.consignmentCounterList = undefined;

                this.consignmentTypeDefer = this.$q.defer();
                this.loadConsignmentTypes();

                this.counterTypeDefer = this.$q.defer();
                this.loadConsignmentCounter();

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.consignmentTypeDefer.promise;
                    });
            }

            consignmentTypeChanged() {

                return this.consignmentService.getConsignmentTypeDefaults(this.conType).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                    
                    this.shipperModel = data.Shipper ? data.Shipper : undefined;
                    this.consigneeModel = data.ShipTo ? data.ShipTo : undefined;
                    this.routeModel = data.Route ? data.Route : undefined;
                    this.supplierModel = data.Supplier ? data.Supplier : undefined;
                    this.customerModel = data.Customer ? data.Customer : undefined;
                    this.consignmentCounterId = data.consignmentCounterId ? data.consignmentCounterId : undefined;
                    this.allowPriceOverride = data.AllowAmendmentCIPriceWhenLinkedtoDI;

                    if (this.conId === 0){
                        this.customNumber = '';
                        this.customNumberCheck = false;
                    }

                    this.allowCustomConsignmentCounter = data.AllowCustomConsignmentCounter;
                   

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise     
            
            }

            //Loading Routes
            loadRouteDefaults() {
                if (this.ownerEntityId) {
                    this.routeCodeService.getDropdownListAll(this.ownerEntityId, this.isInbound).query(
                        {
                        },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                            this.routeCodeList = result;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Load Suppliers
            getSuppliers(searchText: string)
            {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Load Customers
            getCustomers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.customerService.getDropdownList(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            OpenExistingCI() {
                this.disableConsignment = true;
                this.showAllPO = false;
                this.selectedTab = 1;
                this.consignmentTypeChanged();
                this.GetPurchaseOrders();
            }

            changeShow()
            {
                if (this.showAllPO)
                    this.gPOList.data = this.POList;
                else
                    this.gPOList.data = this.selectedPOs;

                this.Show = false;
                this.$timeout(() => {
                    this.Show = true;
                });
            }

            testEntity(row: uiGrid.IGridRow)
            {
                var itemFound = _.find(this.selectedPOs, row.entity);

                if (itemFound)
                    return true;
                else
                    return false;
            }

            //Build UIGrid
            gPOList: uiGrid.IGridOptions = {
                data: [],
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-if="row.entity.IsSelected || grid.appScope.consignmentCreatePurchaseOrderCtrl.showAllPO || grid.appScope.consignmentCreatePurchaseOrderCtrl.testEntity(row)" ui-grid-cell></div>',
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                        name: "ID",
                        displayName: "ID",
                        field: "Id",
                        visible: false,
                        enableFiltering: false
                }, {
                        name: "EDIT",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                        <div> 
                            <button type="button" ui-sref="auth.ConsignmentImports.Update({ conId: row.entity.Id })" class="btn btn-default btn-sm">
                                <span class="fa fa-pencil"></span>
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 35,
                        visible: false                        
                }, {
                        name: "PurchaseOrderNumber",
                        displayName: "PO Number",
                        field: "PurchaseOrderNumber",
                        width: 120,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "InvoiceNumber",
                        displayName: "Invoice Number",
                        field: "InvoiceNumber",
                        width: 120,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`, 
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-model="MODEL_COL_FIELD">{{grid.getCellValue(row, col)}}</div>`,
                        enableCellEdit: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ignore-wheel name="grossmass-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`
                }, {
                        name: "TotalFCValue",
                        displayName: "Total Value",
                        field: "TotalValue",
                        type: "number",
                        cellFilter: "number: 5",
                        width: 140,
                        enableFiltering: false,
                }, {
                        name: "Currency",
                        displayName: "Currency",
                        field: "Currency",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "TotalQuantity",
                        displayName: "Total Quantity",
                        field: "TotalQuantity",
                        type: "number",
                        cellFilter: "number: 2",
                        width: 110,
                        enableFiltering: false,
                }, {
                        name: "Incoterm",
                        displayName: "Incoterm",
                        field: "Incoterm",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },  {
                        name: "Origin",
                        displayName: "Origin",
                        field: "Origin",
                        width: 80,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "Destination",
                        displayName: "Destination",
                        field: "Destination",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "DestinationLocation",
                        displayName: "Destination Location",
                        field: "DestinationLocation",
                        width: 180,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "Warehouse",
                        displayName: "Warehouse",
                        field: "Warehouse",
                        width: 250,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "Reference",
                        displayName: "Reference",
                        field: "Reference",
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "NotBeforeDate",
                        displayName: "Not Before Date",
                        field: "NotBeforeDate",
                        width: 120,
                        cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "NotAfterDate",
                        displayName: "Not After Date",
                        field: "NotAfterDate",
                        cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "TransportMode",
                        displayName: "Transport Mode",
                        field: "TransportMode",
                        width: 150,
                        cellClass: 'text-right',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }]
            };

            gvwILList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApiLI(gridApi);

                    this.apiILList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiILList.selection.selectRow(newCol.row.entity);
                        });
                    });
                   

                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ui-sref="auth.ConsignmentImports.Update({ conId: row.entity.Id })" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: false
                },
                {
                    name: "InvoiceNumber",
                    displayName: "Commercial Invoice",
                    field: "InvoiceNumber",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 150
                },
                {
                    name: "OrderNumber",
                    displayName: "Order Number",
                    field: "OrderNumber",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "LineSequenceNumber",
                    displayName: "#",
                    field: "LineSequenceNumber",
                    width: 32,
                    cellClass: 'text-right',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "SkuCode",
                    displayName: "Sku Code",
                    field: "SkuCode",
                    width: 120,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "SkuDescription",
                    displayName: "Description",
                    field: "SkuDescription",
                    width: 280,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "AvailableQuantity",
                    displayName: "Available Quantity",
                    field: "AvailableQuantity",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "OrderUnitOfMeasureCode",
                    displayName: "UoM",
                    field: "OrderUnitOfMeasureCode",
                    width: 200,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "POPrice",
                    displayName: "PO Unit Price",
                    field: "POPrice",
                    width: 120,
                    cellClass: 'text-right',
                    cellFilter: "number: 5",
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "InvoiceQuantity",
                    displayName: "Actual Qty",
                    field: "InvoiceQuantity",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="grossmass-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" />
                                    </p>
                                </form>`
                },
                {
                    name: "InvoicePrice",
                    displayName: "Actual Price",
                    field: "InvoicePrice",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="number" ignore-wheel name="grossmass-{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="!grid.appScope.consignmentCreatePurchaseOrderCtrl.allowPriceOverride" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" />
                                </p>
                            </form>`
                }]
            };
            
            //Register PO Grid
            registerGridApi(gridApi: uiGrid.IGridApi) {
                
                this.apiPOList = gridApi;
                
                this.apiPOList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    
                    this.selectedPO = selected;

                    var foundItem = _.find(this.selectedPOs, selected.entity);

                    if(!foundItem) {
                        if (selected.isSelected) 
                            this.selectedPOs = _.concat(this.selectedPOs, selected.entity);
                    } else {
                        if (!selected.isSelected) 
                            _.remove(this.selectedPOs, selected.entity);
                    }
                    
                    if (this.apiPOList.selection.getSelectedRows().length > 0) {
                        this.EnableNextButton = true;
                    } else 
                        this.EnableNextButton = false;
                });

                this.$timeout(() => {
                    if(this.selectedPOs)
                    {
                        _.forEach(this.selectedPOs, (po) => {
                            this.apiPOList.selection.selectRow(po);
                        })
                    }
                });
            }

            //Register IL List Grid
            registerGridApiLI(gridApi: uiGrid.IGridApi) {
                
                this.apiILList = gridApi;
                this.apiILList.selection.on.rowSelectionChanged(this.$scope, (selected) => { this.selectedIL = selected; });

                this.$timeout(() => {
                    if (this.selectedIL)
                        this.apiILList.selection.selectRow(this.selectedIL.entity);
                });
            }

            //Load Purchase Orders
            GetPurchaseOrders() { 
                this.disableInvoiceLines = true;
                this.IsLoading = true;
                this.bsLoadingOverlayService.wrap({
                },() => {
                    var params = {
                        ownerEntityId: this.ownerEntityId,
                        supplierId: !this.supId ? (!this.supplierModel ? 0 : this.supplierModel.Id) : this.supId,
                        consignmentTypeId: this.conType,
                        consignmentId: this.conId
                    }

                    this.$timeout(() => {
                        this.commercialInvoiceDeliveryInstructionAllocationService.getDIForCI(
                            params.ownerEntityId,
                            params.supplierId,
                            params.consignmentTypeId,
                            params.consignmentId
                        ).query((data) => {
                            this.POList = data;
                            this.gPOList.data = this.POList.map(obj => {
                                return {...obj,PurchaseOrderNumber:obj.PurchaseOrderNumber + ' - ' + obj.DISequence}
                            })

                            for (let item of this.gPOList.data) {
                                
                                if ((item as interfaces.logistics.ICIDIDisplay).IsSelected) {
                                    this.selectedPO = item;
                                    this.$timeout(() => {
                                        this.apiPOList.selection.unSelectRow(item);
                                        this.apiPOList.selection.selectRow(item);
                                    });
                                }
                            }
                            
                            this.IsLoading = false;
                        },
                        (errorResponse) => {

                            this.generalService.displayMessageHandler(errorResponse.data);
                            this.IsLoading = false;
                        });
                    });
                });
                this.selectedTab = 1;
            }

            ValidateIsSameCurrency() {
                if (this.selectedPOs && this.apiPOList.selection.getSelectedRows().length > 0) {

                    this.orderIdList = [];

                    this.orderIdList = _.map(this.apiPOList.selection.getSelectedRows(), (o) => {
                        return {
                            DeliveryInstructionId: o.DeliveryInstructionId,
                            InvoiceNumber: o.InvoiceNumber,
                            CommercialInvoiceId: o.CommercialInvoiceId,
                            Currency: o.Currency
                        };
                    });
                }

                return this.isCurrencySame(this.orderIdList);
            }

            isCurrencySame(arr) {
            var tmpArr = [];
                for (var obj in arr) {
                    if (tmpArr.indexOf(arr[obj].Currency) < 0) {
                        tmpArr.push(arr[obj].Currency);
                    }
                }

                return tmpArr.length > 1 ? false : true;
        }
            
            //Load Invoice Lines
            GetInvoiceLines() {
                if (this.selectedPOs && this.apiPOList.selection.getSelectedRows().length > 0) {

                    this.orderIdList = [];

                    this.orderIdList = _.map(this.apiPOList.selection.getSelectedRows(), (o) => {
                        return {
                            DeliveryInstructionId: o.DeliveryInstructionId,
                            InvoiceNumber: o.InvoiceNumber,
                            CommercialInvoiceId: o.CommercialInvoiceId
                        };
                    });
                }

                this.IsLoading = true;
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {

                    this.$timeout(() => {

                        this.commercialInvoiceDeliveryInstructionAllocationService.GetDIAllocationLines().query({},

                            this.orderIdList, (data) => {

                                this.ILList = data;
                                this.gvwILList.data = this.ILList;
                                this.IsLoading = false;
                            },
                            (errorResponse) => {
                                this.generalService.displayMessageHandler(errorResponse.data);
                                this.IsLoading = false;
                            });
                    });
                });
            }

            SubmitInvoiceLines() {
                var selectedPurchaseOrderLines: interfaces.logistics.ICIDILineSubmit[] = [];
                var orderIdList: interfaces.logistics.ICIDISubmit[] = [];

                var salesOrderWithAllocationLineSubmit: interfaces.logistics.ICIDIWithAllocationLineSubmit = {
                    SelectedDILines: [],
                    orderIdList: []
                };

                orderIdList = this.orderIdList;

                selectedPurchaseOrderLines = _.map(this.gvwILList.data, (n) => {
                    if (n.InvoiceQuantity != n.AvailableQuantity || n.POPrice != n.InvoicePrice)   return {
                        SkuLineId: n.DISKUAllocationId,
                        CommercialInvoiceId: n.CommercialInvoiceId,
                        CISKUId: n.CISKUId,
                        InvoiceNumber: n.InvoiceNumber,
                        OrderQuantity: n.InvoiceQuantity,
                        InvoicePrice: n.InvoicePrice
                    };
                });

                salesOrderWithAllocationLineSubmit.SelectedDILines = selectedPurchaseOrderLines.filter(n => n);
                salesOrderWithAllocationLineSubmit.orderIdList = orderIdList;

                
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {

                    if (this.$stateParams.conId === null && this.$stateParams.supId === null && this.$stateParams.conType === null) {

                        return this.commercialInvoiceDeliveryInstructionAllocationService.CreateFromDI(

                            this.ownerEntityId,
                            this.conType,
                            this.shipperModel.Id,
                            this.consigneeModel.Id,
                            this.supplierModel.Id,
                            this.customerModel.Id,
                            this.customNumber ? this.customNumber : undefined,
                            this.consignmentCounterId ? this.consignmentCounterId : undefined,
                            this.routeModel ? this.routeModel.Id : undefined,
                            this.varianceAuthUserId ? this.varianceAuthUserId : undefined

                        ).save(salesOrderWithAllocationLineSubmit, (result) => {

                            this.generalService.displayMessageHandler(result);

                            this.IsLoading = false;

                            if (!result.HasErrorMessage) {
                                this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                            }

                        }, (reponseError) => {

                            this.disablePurchaseOrders = false;
                            this.disableInvoiceLines = false;
                            this.IsLoading = false;

                            this.generalService.displayMessageHandler(reponseError.data);

                            }).$promise;

                    } else {

                        return this.commercialInvoiceDeliveryInstructionAllocationService.UpdateFromDI(
                            this.conId,  
                            this.varianceAuthUserId ? this.varianceAuthUserId : undefined
                            /*salesOrderWithAllocationLineSubmit*/

                        ).save(salesOrderWithAllocationLineSubmit, (result) => {

                            this.generalService.displayMessageHandler(result);

                            this.IsLoading = false;                            

                            if (!result.HasErrorMessage) {
                                this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                            }
                            this.close();

                        }, (reponseError) => {

                            this.disablePurchaseOrders = false;
                            this.disableInvoiceLines = false;

                            this.IsLoading = false;
                            this.generalService.displayMessageHandler(reponseError.data);

                        }).$promise;
                    }
                });
            }
            
            NextStep() {
                
                if (this.selectedTab == 0) {
                    this.GetPurchaseOrders();

                } else if (this.selectedTab == 1) {
                    if (this.apiPOList.selection.getSelectedRows().length > 0) {

                        if (this.ValidateIsSameCurrency()) {
                            this.$rootScope.Messages = [];
                            this.GetInvoiceLines();
                            this.selectedTab = 2;
                        }
                        else {
                            this.generalService.displayMessage("Purchase Orders with different Currencies cannot be linked to the same Consignment", Enum.EnumMessageType.Error);
                            this.selectedTab = 1;
                        }
                    }

                } else if (this.selectedTab == 2) {
                    this.SubmitInvoiceLines();
                }
            }

            Submit()
            {
                if (this.apiPOList.selection.getSelectedRows().length > 0)
                {
                    this.GetInvoiceLines();
                    this.SubmitInvoiceLines();
                }
                else
                {
                    this.generalService.displayMessage("Please select a Purchase Order", Enum.EnumMessageType.Warning);
                }
            }

            goBack()
            {
                this.selectedTab = 1;
            }

            tabChanged(selectedTab: number) {
                this.selectedTab = selectedTab;

                if (this.selectedTab == 1) {

                    this.disablePurchaseOrders = false;
                    this.ShowPurchaseOrdersGrid = true;

                    this.NextStepButton = "Next";

                } else if (this.selectedTab == 2) {

                    this.disableInvoiceLines = false;
                    this.ShowInvoiceLinesGrid = true;
                    this.disablePurchaseOrders = false;

                    this.NextStepButton = "Submit";

                } else if (this.selectedTab == 3) {

                    this.disableConsignment = false;
                    this.disablePurchaseOrders = false;
                    this.disableInvoiceLines = false;
                    this.showAllPO = true;

                    this.NextStepButton = "Submit 2";
                } 
            }

            varianceAuthorisation_Click() {

                this.displayVarianceAuthorisation().then((result) => {
                    if (result) {
                        this.varianceAuthUser = result;
                        this.commercialInvoiceSkuService.ValidateUser().save((this.varianceAuthUser), (result: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(result);

                            this.varianceAuthUserId = this.varianceAuthUser.Id;

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                        });
                    }
                });

            }


            displayVarianceAuthorisation() {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="varianceAuthorisation">
                                <div class="gts-padding">
                                    <fieldset id="varianceAuth">
                                    <legend>
                                        Commercial Invoice Variance Authorisation
                                    </legend>                                            
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Username: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': users.$invalid}">
                                                        <gts-dropdown name="users" ng-model="varianceAuthorisationCtrl.user" form="varianceAuthorisation" load-data="varianceAuthorisationCtrl.loadUserList()" required="true"></gts-dropdown>
                                                    </p>
                                                </div>                                               
                                            </div>  
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Password: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': password.$invalid}">
                                                           <input type="password" name="password" autocomplete="off" ng-model="varianceAuthorisationCtrl.password" form="varianceAuthorisation"  required="true"   class="form-control"/>
                                                    </p>
                                                </div>                                               
                                            </div> 
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary" ng-disabled="varianceAuthorisation.$invalid" ng-click="varianceAuthorisationCtrl.ok()">Ok</button>
                                                    <button type="button" class="btn btn-info" ng-click="varianceAuthorisationCtrl.cancel()">Cancel</button>
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class varianceAuthorisationCtrl {
                        user: interfaces.applicationcore.IDropdownModel;
                        password: string;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, private commercialInvoiceSkuService: interfaces.logistics.ICommercialInvoiceSkuService) {

                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        ok() {
                            this.user.Code = this.password;
                            this.$uibModalInstance.close(this.user);
                        }



                        //Load Container ISO.
                        loadUserList() {
                            return this.commercialInvoiceSkuService.getUserAuthorisationDropdownList().query(
                                () => {

                                }, (failureData) => {
                                }).$promise;
                        }
                    },
                    controllerAs: "varianceAuthorisationCtrl",
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

            //Navigation
            close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("consignmentCreatePurchaseOrderCtrl", controllers.consigment.consignmentCreatePurchaseOrderCtrl);
    }
}